import React, { useEffect, useState } from "react";
import style from './index.module.less'
import { FilterSvg } from '@/images/news/index'
import Axios from "axios";
import useBigScreen from "@/hooks/bigScreen"


const month_list = [
    {
        label: "All",
        value: "0"
    },
    {
        label: "1月",
        value: "1"
    }, {
        label: "2月",
        value: "2"
    }, {
        label: "3月",
        value: "3"
    }, {
        label: "4月",
        value: "4"
    },
    {
        label: "5月",
        value: "5"
    },
    {
        label: "6月",
        value: "6"
    }, {
        label: "7月",
        value: "7"
    },
    {
        label: "8月",
        value: "8"
    },
    {
        label: "9月",
        value: "9"
    }
    ,
    {
        label: "10月",
        value: "10"
    },
    {
        label: "11月",
        value: '11'
    },
    {
        label: "12月",
        value: '12'
    }
]
const JPNews = () => {
    const ifPc = useBigScreen(900)
    const [showYear, setShowYear] = useState(new Date().getFullYear())
    const [newsList, setNewsList] = useState()
    const [yearList, setYearList] = useState([])
    const [renderYearList, setRenderYearList] = useState([]) //下拉列表展示的年份
    const [renderMonthList, setRenderMonthList] = useState([]) //下拉列表展示的yuefen
    const [ifShowYearList, setIfShowYearList] = useState(false)
    const [ifShowMonthList, setIfShowMonthList] = useState(false)
    const [showMonth, setShowMonth] = useState(0)
    const [renderList, setRenderList] = useState([])
    const [renderMonthText, setRenderMonthText] = useState('All')
    const [ifShowMore, setIfShowMore] = useState(false)
    useEffect(() => {
        let newsPath = `${process.env.resourceUrl}docs/news/jp/newsAll.json`;
        Axios.get(newsPath).then((data) => {
            setNewsList(data.data)
            setYearList(Object.keys(data.data).reverse())//年份都有什么
        })
    }, [])
    useEffect(() => {
        //年份列表选择器
        const render_year_list = yearList.filter(item => item != showYear);
        setRenderYearList(render_year_list)
        setShowMonth(0)
    }, [showYear, yearList])

    useEffect(() => {

        if (!!newsList) {
            if (showMonth == 0) {
                setRenderList(newsList[showYear])

            } else {
                let filterArr = newsList[showYear].filter(item => item.month == showMonth)
                setRenderList(filterArr)


            }
            setIfShowMore(false)

        }
    }, [showYear, showMonth, newsList])
    useEffect(() => {
        let showMonthText = month_list.filter(item => item.value == showMonth)

        setRenderMonthText(showMonthText[0].label)
    }, [showMonth])
    useEffect(() => {

        const renderMonthList = month_list.filter(item => item.value != showMonth);
        setRenderMonthList(renderMonthList)
    }, [showYear, yearList, showMonth])
    return (
        <div className={style.box}>
            <div className={style.title}>
                News
            </div>

            <div className={style.filter}>
                <div className={style.filterYear} onClick={() => { setIfShowYearList(!ifShowYearList) }}
                    style={{ borderRadius: ifShowYearList ? '17px 17px 0 0' : '17px' }}
                >
                    <div className={style.filterYearShow}

                    >
                        {showYear}
                        <img src={FilterSvg} style={{ transform: ifShowYearList ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </div>

                    <div className={style.filterYearBox} style={{ height: ifShowYearList ? 'auto' : '0' }}>
                        {renderYearList.map((item, index) => (
                            <div className={style.filterYearBoxItem} key={`filterYearBoxItem${index}`} onClick={() => { setShowYear(item) }}>
                                {item}
                            </div>
                        ))}

                    </div>
                </div>
                {ifPc ? <div className={style.filterMonth}

                >
                    {month_list.map((item, index) => (
                        <div className={style.filterMonthItem} key={`filterMonthItem${index}`}
                            style={{ color: showMonth == item.value ? '#000' : '#999' }}
                            onClick={() => setShowMonth(item.value)}
                        >{item.label}</div>
                    ))}
                </div> :
                    <div className={style.filterYear} onClick={() => { setIfShowMonthList(!ifShowMonthList) }}
                        style={{
                            borderRadius: ifShowMonthList
                                ? '17px 17px 0 0' : '17px'
                        }}
                    >
                        <div className={style.filterYearShow}>
                            {renderMonthText}
                            <img src={FilterSvg} style={{ transform: ifShowMonthList ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                        </div>

                        <div className={style.filterYearBox} style={{ height: ifShowMonthList ? 'auto' : '0' }}>
                            {renderMonthList.map((item, index) => (
                                <div className={style.filterYearBoxItem} key={`filterYearBoxItem${index}`} onClick={() => { setShowMonth(item.value) }}>
                                    {item.label}
                                </div>
                            ))}

                        </div>
                    </div>
                }

            </div>
            <div className={style.content}>
                {
                    renderList.length > 4 ? <>
                        {renderList.slice(0, 4).map((item, index) => (
                            <div className={style.listItem} onClick={() => window.open(item.link)}
                                key={`renderList_jpnews_${index}`}
                            >
                                <div className={style.listItemImg} style={{ backgroundImage: `url(${item.img})` }}>

                                </div>
                                <div className={style.listItemDate}>
                                    {item.date}
                                </div>
                                <div className={style.listItemTitle}>
                                    {item.title}
                                </div>
                                <div className={style.listItemDesc}>
                                    {item.desc}
                                </div>
                            </div>
                        ))}
                    </> : <>
                        {renderList.map((item, index) => (
                            <div className={style.listItem}
                                key={`renderList_jpnews_${index}`}
                                onClick={() => window.open(item.link)}
                            >
                                <div className={style.listItemImg} style={{ backgroundImage: `url(${item.img})` }}>

                                </div>
                                <div className={style.listItemDate}>
                                    {item.date}
                                </div>
                                <div className={style.listItemTitle}>
                                    {item.title}
                                </div>
                                <div className={style.listItemDesc}>
                                    {item.desc}
                                </div>
                            </div>
                        ))}
                    </>
                }
                {renderList.length == 0 && <div className={style.showMore} style={{ cursor: "auto" }}>No More Articles.</div>}
            </div>
            {renderList.length > 4 && !ifShowMore && <div className={style.showMore} onClick={() => setIfShowMore(true)}>更多 +</div>}
            {renderList.length > 4 && ifShowMore && <div className={style.content}>

                {renderList.slice(4).map((item, index) => (
                    <div className={style.listItem} onClick={() => window.open(item.link)}
                        key={`renderList_jpnews_${4 + index}`}
                    >
                        <div className={style.listItemImg} style={{ backgroundImage: `url(${item.img})` }}>

                        </div>
                        <div className={style.listItemDate}>
                            {item.date}
                        </div>
                        <div className={style.listItemTitle}>
                            {item.title}
                        </div>
                        <div className={style.listItemDesc}>
                            {item.desc}
                        </div>
                    </div>
                ))}


            </div>}
        </div>
    )
}

export default JPNews