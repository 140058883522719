import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import style from "./all.module.less"
import SEO from "../../components/seo"
import { newsBg, newsJPBg } from '@/images/news/index'
import { Select } from "@/components/input/index"
import getCountry from '@/utils/getCountry.js'
import Axios from "axios";
import useBigScreen from "@/hooks/bigScreen"
import { JPNews, JPNewsKol, JPNewsReview ,JPNewsEvent} from '@/components/News/index'
import { useIntl } from "react-intl"


const monthTab = [
  {
    label: "Dec",
    value: "12"
  },
  {
    label: "Nov",
    value: "11"
  }, {
    label: "Oct",
    value: "10"
  }, {
    label: "Sep",
    value: "9"
  }, {
    label: "Aug",
    value: "8"
  },
  {
    label: "Jul",
    value: "7"
  },
  {
    label: "Jun",
    value: "6"
  }, {
    label: "May",
    value: "5"
  },
  {
    label: "Apr",
    value: "4"
  },
  {
    label: "Mar",
    value: "3"
  }
  ,
  {
    label: "Feb",
    value: "2"
  },
  {
    label: "Jan",
    value: '1'
  }
]
const Index = () => {
  const intl = useIntl()
  const country = getCountry()
  const ifPc = useBigScreen(880)
  const [checkedYear, setCheckedYear] = useState(0)
  const [checkedMonth, setCheckedMonth] = useState(0)
  const [newsList, setNewsList] = useState([])
  const [renderList, setRenderList] = useState([])
  const [yearList, setyearList] = useState([{ label: "All Years", value: 0 }])
  const [monthList, setMonthList] = useState([{ label: "All Months", value: 0 }])
  const [kolList, setKolList] = useState([])
  const [reviewList, setReviewList] = useState([])
  const [eventList , setEventList]=useState([])
  useEffect(() => {
    let newsPath = `${process.env.resourceUrl}docs/news/newsData.json`;
    if (country === 'jp') {
      newsPath = `${process.env.resourceUrl}docs/news/jp/newsData_jp.json`;
    }
    Axios.get(newsPath).then((data) => {
      setNewsList(data.data)
      //获取全部新闻列表
      let allNewsList = []
      Object.values(data.data).reverse().forEach(ele => {
        allNewsList.push(...ele)
      })
      setRenderList(allNewsList)

      //获取年份列表
      let yearArr = [{ label: "All Years", value: 0 }]
      Object.keys(data.data).reverse().map((item, index) => {
        let obj = { label: item, value: item }
        yearArr.push(obj)
      })
      setyearList(yearArr)
    })
  }, [])
  useEffect(() => {
    if (country == 'jp') {
      let newsPath = `${process.env.resourceUrl}docs/news/jp/newskol.json`;
      Axios.get(newsPath).then((data) => {
        setKolList(data.data['kol_list'])
        setReviewList(data.data['review_list'])
        setEventList(data.data['event_list'])
      })
    }

  }, [])
  useEffect(() => {
    //选择年份后更新月份列表
    let nowYear = new Date().getFullYear()
    if (nowYear == checkedYear) {
      let nowMonth = new Date().getMonth() + 1
      let monthChange = monthTab.filter(item => item.value < nowMonth || item.value == nowMonth)
      let monthArr = [{ label: "All Months", value: 0 }, ...monthChange]
      setMonthList(monthArr)
    } else {
      let monthArr = [{ label: "All Months", value: 0 }]
      monthArr = [...monthArr, ...monthTab]
      setMonthList(monthArr)
    }
    setCheckedMonth(0)
  }, [checkedYear])

  useEffect(() => {
    //  更新新闻列表
    if (checkedYear == 0) {
      let allNewsList = []
      Object.values(newsList).reverse().forEach(ele => {
        allNewsList.push(...ele)
      })
      setRenderList(allNewsList)
    } else if (checkedMonth == 0) {
      setRenderList(newsList[checkedYear])
    } else {

      let nowYearList = newsList[checkedYear]
      let filterArr = nowYearList.filter(item => item.month == checkedMonth)
      setRenderList(filterArr)
    }

  }, [checkedYear, checkedMonth])
  return (
    <Layout menuBlack={country=='jp'?true:false} footerBackground='#f2f2f2' footerTextWhite={false}>
      <SEO page="News" />
      {country !== 'jp' ? <div className={style.all}>
        <div className={style.header} style={{ backgroundImage: `url(${newsBg})` }}>
          {intl.formatMessage({ id: "news_header_title", defaultMessage: "News Center" })}
        </div>
        {/* 暂时不要筛选部分 */}
        {/* {country == 'jp' && <div className={style.filter}>
          <div className={style.filterBox}>
            <div className={style.filterBoxTitle}>
              {intl.formatMessage({ id: "news_filter", defaultMessage: "Filter" })}
            </div>
            <Select
              setCheckedValue={setCheckedYear}
              options={yearList}
              defaultValue={checkedYear}
              styles={{ marginRight: ifPc ? "20px" : '', marginBottom: ifPc ? '' : "20px" }}
            >
            </Select>
            <Select
              setCheckedValue={setCheckedMonth}
              options={monthList}
              defaultValue={checkedMonth}
              disabled={checkedYear == 0 ? true : false}
            >
            </Select>
          </div>
        </div>} */}

        <div className={style.list}>
          <div className={style.listTitle} style={{ marginTop: country == 'jp' ? '60px' : "80px" }}>
            {intl.formatMessage({ id: "news_blog", defaultMessage: "Blog" })}
          </div>
          <div className={style.listBox}>
            {!!renderList[0] ?
              <>{renderList.map((item, index) => (
                <div className={style.listBoxItem} key={`allNews${index}`} onClick={() => window.open(item.link)}>
                  <div className={style.listBoxItemL}>
                    <div className={style.listBoxItemL_img} style={{ backgroundImage: `url(${item.img})` }}></div>
                  </div>
                  <div className={style.listBoxItemR}>
                    <div className={style.listBoxItemR_title}>{item.title}</div>
                    <div className={style.listBoxItemR_desc}>
                      {item.desc}
                    </div>
                    <div className={style.listBoxItemR_date}>
                      {item.date}
                    </div>
                  </div>
                </div>
              ))}</> : <div className={style.noNews}>No more articles.</div>
            }


          </div>
        </div>
      </div> :
        <>
          <div className={style.header} style={{ backgroundImage: `url(${newsJPBg})`, backgroundPosition: "center 15%" }}>
          </div>
          <JPNews />
          <JPNewsKol kolList={kolList}/>
          <JPNewsReview reviewList={reviewList}/>
          <JPNewsEvent eventList={eventList}/>
        </>}


    </Layout>
  )
}


export default Index

